<template lang="pug">
	#me-root
		#me-dialogs
			el-dialog.override-width.search-dialog(title="Two Factor Authentication" :visible.sync="showAuthenticatorModal" width="75%")
				authenticator(@onFinish="changesHandler")
			el-dialog.override-width.search-dialog(title="Update Self Data" :visible.sync="showUpdatesModal" width="75%")
				me-updates(@onFinish="changesHandler")
		#me-section
			.widget
				el-card.box-card
					.clearfix.d-flex.justify-content-start(slot="header")
						.d-flex.justify-content-between.w-100
							el-col.text-left(:span="24")
								h3 <b> My Profile </b>
								span {{ me.first_name | capitalize }} {{ me.last_name | capitalize }}
								| &nbsp;&nbsp;
								small.text-success
									b ({{ me.role_name.toLowerCase() | capitalize }})

							//.ml-1
								el-button( :title="`Update my details`", :type="`success`", size="small",  :icon="`el-icon-edit`" @click="showUpdatesModal = true")
							.ml-1
								el-button( v-if="!me.two_factor_auth" :title="`Enable 2FA (Google Authenticator)`", :type="`warning`", size="small",  :icon="`el-icon-guide`" @click="showAuthenticatorModal = true")
								el-button( v-else="" :title="`Disable 2FA (Google Authenticator)`", :type="`danger`", size="small",  icon="el-icon-guide" @click="turnOffOtp")

					el-row.text-left
						h5
							b Account Details
						br
						//pre {{ me }}
						el-row(:gutter="24")
							el-col.sm-gap-1.mt-lg-3(:xs="24" :sm="12" :lg="8")
								label.d-block
									b First Name
								span {{ me.first_name  || '-'}}
							el-col.sm-gap-1.mt-lg-3(:xs="24" :sm="12" :lg="8")
								label.d-block
									b Last Name
								span {{ me.last_name || '-' }}
							el-col.sm-gap-1.mt-lg-3(:xs="24" :sm="12" :lg="8")
								label.d-block
									b Username
								span {{ me.username || '-' }}

							el-col.sm-gap-1.mt-lg-3(:xs="24" :sm="12" :lg="8")
								label.d-block
									b E-Mail Address
								span {{ me.email || '-' }}

							el-col.sm-gap-1.mt-lg-3(:xs="24" :sm="12" :lg="8")
								label.d-block
									b Last Login
								span {{ formatDate(me.last_login_raw) }}
							el-col.sm-gap-1.mt-lg-3(:xs="24" :sm="12" :lg="8")
								label.d-block
									b Role
								span.text-success
									b {{ me.role_name.toLowerCase() | capitalize }}
							el-col.sm-gap-1.mt-lg-3(:xs="24" :sm="12" :lg="8")
								label.d-block
									b Two Factor Authentication
								span.text-success
									b(v-bind:class="{'text-success' : me.two_factor_auth, 'text-danger': !me.two_factor_auth}") {{ me.two_factor_auth ? 'Set' : 'Not Set' }}
						el-divider.mb-3
						h5
							b Assigned Integrations
						br

						el-row.mt-lg-3(:gutter="24")
							el-col.sm-gap-1(:xs="24" :sm="12" :lg="8" v-if="me.role === '4'")
								label.d-block
									b API Token&nbsp;
										el-tooltip(content="For security reasons, you cannot view the api token")
											i.el-icon-question.chooseable.no-deco()
								span {{ me.api_token || '-' }}

</template>

<style lang="scss">

</style>

<script>
	import Vue from 'vue';
	import { $me } from "@/struct";
	import moment from 'moment';
	import Authenticator from "./authenticator";
	import meUpdates from "./update";

	export default {
		components: {Authenticator, meUpdates},
		data() {
			return {
				me: $me,
				showAuthenticatorModal: false,
				showUpdatesModal: false
			}
		},
		async beforeRouteEnter(to, from, next) {
			if (to.name !== 'me') next('/')
			try {
				let result = await Vue.apix.sendHttpRequest('GET', 'me/');
				next(vm => {
					vm.me = result;
				})
			} catch (e) {
				console.error(e);
				next('/')
			}
		},
		methods: {
			formatDate(date) {
				return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss')
			},
			changesHandler(modalFlag) {
				this[modalFlag] = false;
				this.$apix.sendHttpRequest('GET', 'me/').then(res => this.me=res);
			},
				turnOffOtp(id) {
						let that = this;
						const $msg = `Are you sure that you want to turn off two factor authentication? The goal of this security measure is to make sure that no one has an access to your account except you.
						You will have to redo all the flow to re-add this feature.
						Click on "OK" to proceed.`
						this.$confirm($msg, 'Turn Off 2FA')
								.then(() => {
										try {
												this.$apix.sendHttpRequest('PUT', 'me/toggle/2fa').then(() => {
														that.$notify.success('2FA turned off successfully successfully');
														this.$apix.sendHttpRequest('GET', 'me/').then(res => this.me=res);
												});
										} catch (err) {
												console.error(err);
										}
								})
								.catch(err => {
										console.error(err);
								});
				},
		}
	}
</script>
